import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ImmutablePropTypes from 'react-immutable-proptypes';

class ChatMessage extends PureComponent {

  static propTypes = {
    status: ImmutablePropTypes.map.isRequired,
    fromUs: PropTypes.bool,
    key: PropTypes.number,
  };

  render() {
    const { status, fromUs } = this.props;

    // const statusObject = status;
    const statusObject = JSON.parse(JSON.stringify(status));

    return (
      <div
        key={this.props.key}
        className={`chat-aside__messages-content-box  chat-aside__messages-content${!fromUs ? '-other-person' : ''}`}>
        <div className='chat-message__content'>
          <div className='chat-message__author'>
            {statusObject.account && statusObject.account.acct}
          </div>
          <div
            className='chat-message__text'
            dangerouslySetInnerHTML={{ __html: statusObject.contentHtml }}
          />
          <div className='chat-message__timestamp'>
            {/* {JSON.stringify(status, null, 2)} */}
            <a href={statusObject.url}>

              {new Date(statusObject.created_at).toLocaleString('fr-FR', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}

              {/*<RelativeTimestamp timestamp={status.get('created_at')} />*/}
              {/*{status.get('edited_at') && <abbr*/}
              {/*  title={intl.formatMessage(messages.edited, {*/}
              {/*    date: intl.formatDate(status.get('edited_at'), {*/}
              {/*      year: 'numeric',*/}
              {/*      month: 'short',*/}
              {/*      day: '2-digit',*/}
              {/*      hour: '2-digit',*/}
              {/*      minute: '2-digit',*/}
              {/*    }),*/}
              {/*  })}> *</abbr>}*/}

            </a>

            {/* <pre>{JSON.stringify(status, null, 2)}</pre> */}


          </div>
        </div>
      </div>
    );
  }

}

export default ChatMessage;
