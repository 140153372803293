// import logo from '@/images/wave-drawer-glitched.svg';
// import illustration_svg from '@/images/elephant_ui_conversation.svg';
// import logo_text_white from '@/images/wordmark-white-text-claire.svg';
// import logo_text_black from '@/images/wordmark-black-text-claire.svg';
import { PureComponent } from 'react';

import coeur_pattes from '@/images/Masto-A1-coeur-pattes.png';
import linux_pingouin from '@/images/Masto-A2-Linux-Pingouin.png';
import love from '@/images/Masto-B1-love.png';
import carnaval_rio from '@/images/Masto-B2-CarnavalRio.png';
import marguerite from '@/images/Masto-C2-marguerite.png';
import poisson from '@/images/Masto-D1-Poisson-Avril.png';
import douche from '@/images/Masto-D2-LaDouche.png';
import punk_duck from '@/images/Masto-E1-Punk-Duck.png';
import duo from '@/images/Masto-E2-Duo-Dos.png';
import environnement from '@/images/Masto-F1-Environnement.png';
import musique from '@/images/Masto-F2-Musique.png';
import fediverse from '@/images/Masto-G1-Fediverse.png';
import hamac from '@/images/Masto-G2-Hamac.png';
import geisha from '@/images/Masto-H1-geisha.png';
import ganesh from '@/images/Masto-H2-Ganesh.png';
import mammouth from '@/images/Masto-I1-Masto-mammouth.png';
import automne from '@/images/Masto-I2-Automne.png';
import animaux from '@/images/Masto-J1-animaux.png';
import halloween from '@/images/Masto-J2-Halloween.png';
import dia_de_los_muertos from '@/images/Masto-K1-DiaDeLosMuertos.png';
import tolerance from '@/images/Masto-K2-tolérance.png';
import firefox from '@/images/Masto-L1-Firefox.png';
import noel from '@/images/Masto-L2-Noel.png';
import journee_droits_des_femmes from '@/images/mastodon-journée-droits-des-femmes.png';

const illu_par_mois = {
  '01-1': coeur_pattes,
  '01-2': linux_pingouin,
  '02-1': love,
  '02-2': carnaval_rio,
  '03-1': journee_droits_des_femmes,
  '03-2': marguerite,
  '04-1': poisson,
  '04-2': douche,
  '05-1': punk_duck,
  '05-2': duo,
  '06-1': environnement,
  '06-2': musique,
  '07-1': fediverse,
  '07-2': hamac,
  '08-1': geisha,
  '08-2': ganesh,
  '09-1': mammouth,
  '09-2': automne,
  '10-1': animaux,
  '10-2': halloween,
  '11-1': dia_de_los_muertos,
  '11-2': tolerance,
  '12-1': firefox,
  '12-2': noel,
};

// on sélectionne l'illustration selon le mois et la moitié du mois
const premiere_partie_du_mois = new Date().getDate() < 15 ? '1' : '2';

const mois_courant_avec_zero = (new Date().getMonth() + 1).toString().padStart(2, '0');

console.log(`Première partie du mois ? ${premiere_partie_du_mois ? 'Oui' : 'Non'}`);
console.log(`Mois courant ? ${mois_courant_avec_zero}`);
console.log(`Illustration ? ${illu_par_mois[`${mois_courant_avec_zero}-${premiere_partie_du_mois}`]}`, `${mois_courant_avec_zero}-${premiere_partie_du_mois}`);


let illustration = illu_par_mois[`${mois_courant_avec_zero}-${premiere_partie_du_mois}`];
console.log('illustration : ', illustration);
// override
// illustration = journee_droits_des_femmes;

class CurrentMammouth extends PureComponent {
  render() {
    return (
      <div className='current-mammouth'>
        {/* <img
          src={illustration}
          alt='Mastodon Logo par @ClaireLemoine'
          style={{ maxWidth: '100%', minHeight: '400px' }}
        /> */}
        <a href='/@ClaireLemoine' className='attribution small'>
          <img src={illustration} alt='Mastodon thématique, par @ClaireLemoine' />
          {/* <span>Illustration par @ClaireLemoine</span> */}

        </a>
        {/* <img src={logo_text_white} alt='Mastodon Logo' className='logo-text-white' />
        <img src={logo_text_black} alt='Mastodon Logo' className='logo-text-black' /> */}
      </div>
    );
  }
}

export default CurrentMammouth;
