import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Composant pour un élément de conversation
class ConversationItem extends PureComponent {
  static propTypes = {
    conversation: ImmutablePropTypes.map.isRequired,
    isActive: PropTypes.bool,
    onOpenConversation: PropTypes.func.isRequired,
    getAccountInfo: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { conversation, onOpenConversation } = this.props;
    onOpenConversation(conversation);
  }

  render() {
    const { conversation, isActive, getAccountInfo } = this.props;

    // Vérifier si getAccountInfo est une fonction
    const accountInfo = typeof getAccountInfo === 'function' && conversation.get('accounts').size > 0
      ? getAccountInfo(conversation.getIn(['accounts', 0]))
      : null;

    return (
      <button
        className={isActive ? 'conversation-item active-conversation' : 'conversation-item'}
        onClick={this.handleClick}
      >
        <div className='conversation-item__content'>
          {accountInfo && (
            <span className='conversation-item-container'>
              <span className='account__avatar'>
                <img
                  className='display-avatar__image'
                  src={accountInfo.get('avatar')}
                  alt="avatar de l'utilisateur"
                />
              </span>
              <span className='conversation-item__display-name'>
                <span>{accountInfo.get('acct')}</span>
                {/* {isActive && <span>Active</span>}
                id:
                {conversation.get('id')} */}
              </span>
            </span>
          )}
          {conversation.get('unread') && (
            <span className='conversation-item__badge'>-</span>
          )}
        </div>
      </button>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.getIn(['accounts']),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationItem);
