import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { submitComposeSuccess } from 'flavours/glitch/actions/compose';
import { expandConversations, expandConversationsRequest } from 'flavours/glitch/actions/conversations';
import api from 'flavours/glitch/api';
import { Icon } from 'flavours/glitch/components/icon';
import ChatMessage from 'flavours/glitch/features/ui/components/chat_message';

import ConversationItem from './conversation_aside';

/**
 * @class ChatAside
 * @augments {PureComponent}
 * @description ChatAside is a component that displays a chat aside of the main timeline. it allows to see a list of conversations and to compose a message to a specific conversation.
 */
class ChatAside extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    conversations: ImmutablePropTypes.list,
    accounts: ImmutablePropTypes.map,
    loading: PropTypes.bool,
    hasMore: PropTypes.bool,
    statuses: ImmutablePropTypes.map,
    contexts: ImmutablePropTypes.map,
    compose: ImmutablePropTypes.map,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      activeConversation: null,
      message: '',
      in_reply_to_id: null,
      newMessages: 0,
      conversationLastMessage: null,
      conversationLastMessageBefore: null,
      conversationStatuses: [],

    };

    // Lier les méthodes au contexte this
    this.getAccountInfo = this.getAccountInfo.bind(this);
    this.handleOpenConversation = this.handleOpenConversation.bind(this);
    this.scrollDownMessages = this.scrollDownMessages.bind(this);
  }

  scrollDownMessages() {
    const messagesContent = document.getElementById('chat_aside_messages_content');
    if (messagesContent) {
      messagesContent.scrollTop = messagesContent.scrollHeight;
    }
  }

  toggleCollapsed = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
    if (!this.state.collapsed) {
      this.focusChatAsideCompose();
    }
  };
  focusChatAsideCompose = () => {
    const compose = document.querySelector('#compose_direct_chat_message');
    if (compose) {
      compose.focus();
      console.log('compose focusé');
    } else {
      console.log('compose non trouvé');
    }
  };
  componentDidMount = () => {
    // Déclencher le chargement des conversations
    this.props.dispatch(expandConversations());
    if (!this.state.collapsed) {
      this.focusChatAsideCompose();
    }

  };


  componentDidUpdate = (prevProps) => {
    // Réagir quand les conversations sont mises à jour après CONVERSATIONS_FETCH_SUCCESS

    // Sélectionner automatiquement la première conversation si aucune n'est active
    if (prevProps.conversations !== this.props.conversations && !this.state.activeConversation && this.props.conversations && this.props.conversations.size > 0) {

      const firstConversation = this.props.conversations.first();
      console.log('set first conversation after load');
      // this.setState({
      //
      // })
      this.handleOpenConversation(firstConversation);
    }

  };

  /**
   * envoyer un message à un utilisateur en réponse à un message
   * @param {Event} e - L'événement de soumission du formulaire
   */
  handleSubmitChatMessage = (e) => {
    e.preventDefault();
    console.log('handleSubmitChatMessage', this.state.message);

    // on crée un nouveau statut en réponse
    const status = this.state.message;
    let in_reply_to_id = '';
    if (this.state.in_reply_to_id && this.state.message.length) {
      in_reply_to_id = this.state.in_reply_to_id;
    } else if (this.state.conversationLastMessageBefore) {
      in_reply_to_id = this.state.conversationLastMessageBefore.id;
    }
    if (!in_reply_to_id || !status) {
      console.log('handleSubmitChatMessage in_reply_to_id or status is null');
      this.props.dispatch(expandConversations());
      return;
    }

    api().request({
      url: '/api/v1/statuses', method: 'post', data: {
        status,
        in_reply_to_id: in_reply_to_id,
        media_ids: [],
        media_attributes: [],
        sensitive: false,
        spoiler_text: '',
        visibility: 'direct',
        poll: null,
        language: 'fr',
      }, headers: {
        'Idempotency-Key': this.props.compose.get(['idempotencyKey']),
      },
    }).then((response) => {
      console.log('handleSubmitChatMessage response', response);
      this.props.dispatch(submitComposeSuccess({ ...response.data }));
      this.props.dispatch(expandConversations());
      this.props.dispatch(expandConversationsRequest());
      this.setState({
        message: '', in_reply_to_id: response.data.id,
      });
      let currentConversationStatuses = this.state.conversationStatuses.push(response.data);
      console.log('handleSubmitChatMessage currentConversationStatuses', currentConversationStatuses);
      this.setState({ conversationStatuses: currentConversationStatuses });

      this.handleOpenConversation(this.state.activeConversation);
      console.log('activeConversation', this.state.activeConversation);
      this.focusChatAsideCompose();
    }).catch((error) => {
      console.log('handleSubmitChatMessage error', error);
    });
  };

  updateMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  getAccountInfo(accountId) {
    const account = this.props.accounts.get(accountId);
    return account;
  }

  handleCloseConversation = () => {
    this.setState({ activeConversation: null });
  };

  getMessageInStore(statusId) {
    return this.props.statuses.get(statusId);
  }

  handleOpenConversation = (conversation) => {
    console.log('------------ handleOpenConversation conversation', conversation.toJS());
    this.setState({
      activeConversation: conversation, in_reply_to_id: conversation.toJS().last_status, conversationStatuses: [],
    });
    this.updateConversationStatuses();
  };

  updateConversationStatuses = () => {
    if (this.state.activeConversation) {

      let statuses = this.findAllStatusesInConversation(this.state.activeConversation);
      console.log('statuses', statuses);
      if (statuses) {

        this.setState({
          conversationStatuses: statuses,
        });
        this.render();
      } else {
        console.log('!!!!!!!!!! pas de status dans la conversation active', this.state.activeConversation);
      }
    } else {
      console.log('!!!!!!!!!!! pas de conversation active', this.state.activeConversation);
    }
  };

  sendChatWithEnter = (e) => {
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmitChatMessage(e);
    }
  };

  getPreviousStatusRepliedTo = (lastStatus) => {
    if (!lastStatus) {
      console.log('!!!!!!!!! pas de dernier status');
      return null;
    }
    let previousMessage = null;
    let reply_to_id = lastStatus.get('in_reply_to_id');
    // console.log('findAllStatusesInConversation reply_to_id', reply_to_id);
    if (reply_to_id) {

      // Vérifier si le statut en réponse existe avant d'y accéder
      let replyToStatus = this.getMessageInStore(reply_to_id);
      if (replyToStatus) {
        previousMessage = replyToStatus;
        // console.log('findAllStatusesInConversation previousMessage', previousMessage);
      }

    } else {
      console.log('!!!!!!!!!! pas de reply_to_id', reply_to_id);
    }
    return previousMessage;
  };

  findAllStatusesInConversation = (conversation) => {
    console.log('findAllStatusesInConversation conversation', conversation);
    // Vérifier si la conversation et le dernier statut existent
    if (!conversation || !conversation.get('last_status')) return [];

    let lastStatusId = conversation.get('last_status');
    let list_statuses = [];

    let lastStatus = this.getMessageInStore(lastStatusId);

    // Vérifier si lastStatus existe
    if (lastStatus) {
      list_statuses.push(lastStatus);

      /**
       * on remonte la chaine des status qui répondent au dernier message de la conversation
       */
      let has_previous_messages_to_find = false;
      let max_fetch_messages = 10;
      let ii_loop = 0;
      if (lastStatus && lastStatus.get('in_reply_to_id')) {

        while (has_previous_messages_to_find || ii_loop < max_fetch_messages) {
          // console.log('findAllStatusesInConversation lastStatus ' + ii_loop + ' a un message précédent', lastStatus);
          let previous = this.getPreviousStatusRepliedTo(lastStatus);
          list_statuses.push(previous?.toJS());
          lastStatus = previous;
          has_previous_messages_to_find = (lastStatus && lastStatus.get('in_reply_to_id') !== null);
          // console.log('findAllStatusesInConversation le message précédent a il un message a prendre lui aussi ? ',
          //   has_previous_messages_to_find, lastStatus);
          ii_loop++;
          // console.log('has_previous_messages_to_find', has_previous_messages_to_find, ii_loop);
          if (!has_previous_messages_to_find) {
            break;
          }
        }


      }
      // console.log('findAllStatusesInConversation fin de remontée des messages');
      this.setState({
        conversationLastMessage: lastStatus?.toJS(), // conversationLastMessageBefore: tempLastMessageBefore,
        in_reply_to_id: lastStatusId,
      });
    }

    console.log('findAllStatusesInConversation list_statuses', list_statuses);

    list_statuses.reverse();
    return list_statuses;
  };

  render() {
    const { conversations, loading } = this.props;
    const {
      collapsed, message, activeConversation, conversationStatuses,
    } = this.state;
    let activeInterlocuteur = '';
    if (activeConversation) {

      let activeConversationObject = activeConversation.toJS();

      if (activeConversationObject && this.getAccountInfo(activeConversationObject?.accounts[0])) {
        activeInterlocuteur = this.getAccountInfo(activeConversationObject?.accounts[0]);
        console.log('activeInterlocuteur', activeInterlocuteur);

      }
    }
    console.log('conversationStatuses', conversationStatuses);

    return (<div className={`chat-aside ${collapsed ? 'chat-aside--collapsed' : 'chat-aside--expanded'}`}>
      <div className='chat-aside__toggle'>
        <button
          className='chat-aside__header'
          onClick={this.toggleCollapsed}
        >
          <Icon id='envelope' icon='envelope' />
          Chat
          {activeInterlocuteur && (<>
            <span className='active-conversation-info'>
                avec

              <a href={activeInterlocuteur.url}>
                <img src={activeInterlocuteur.avatar} className='account__avatar' alt='avatar' />
                {activeInterlocuteur.username}
              </a>

            </span>
            {conversationStatuses.length && (<span className='counter'>
              {conversationStatuses.length}

            </span>)}
          </>)}
        </button>
      </div>

      {!collapsed && (<div className='chat-aside__content'>
        <div className='chat-aside__conversations'>
          <h3>

            <span className='badge chat-aside__conversations-counter'>
              {!loading && conversations && conversations.size}
            </span>
            Conversations
          </h3>
          <div className='chat-aside__conversations-list'>
            {loading && (<div className='loading-indicator'>
              <div className='loading-indicator__figure' />
              Chargement
            </div>)}

            {!loading && conversations && conversations.size > 0 ? (<div className='conversations-list'>
              {conversations.map(conversation => (<ConversationItem
                key={conversation.get('id')}
                conversation={conversation}
                isActive={activeConversation && activeConversation.get('id') === conversation.get('id')}
                onOpenConversation={this.handleOpenConversation}
                getAccountInfo={this.getAccountInfo}
                dispatch={this.props.dispatch}
              />))}
            </div>) : (!loading && <p>Aucune conversation</p>)}
            <hr />
          </div>
        </div>

        {activeConversation && (<div className='chat-aside__messages'>
          <div className='chat-aside__messages-header'>
            <button onClick={this.handleCloseConversation}>
              fermer
            </button>
          </div>
          <div className='chat-aside__messages-content' id='chat_aside_messages_content'>
            {conversationStatuses.length > 0 && conversationStatuses.map((status) => (
              <div key={'c_' + status.id} className='chat-message-container'>
                {status && (<ChatMessage
                  key={status.id}
                  status={status}
                  fromUs={false}
                />)}
              </div>
            ))}

          </div>
          <div className='chat-aside__messages-input'>


            <form action='' onSubmit={this.handleSubmitChatMessage}>
              <textarea
                name='compose_direct_chat_message'
                id='compose_direct_chat_message'
                cols='30'
                rows='2'
                value={message}
                onChange={this.updateMessage}
                onKeyDown={this.sendChatWithEnter}
              />
              <button className='submit-button' type='submit'>📦 Envoyer</button>

            </form>
          </div>
        </div>)}
      </div>)}
    </div>);
  }
}

/**
 * Récupère les conversations et autres données du store Redux
 * @param {Object} state - L'état global Redux
 * @returns {Object} Les props mappées pour le composant
 */
const mapStateToProps = state => ({
  conversations: state.getIn(['conversations', 'items']),
  statuses: state.getIn(['statuses']),
  loading: state.getIn(['conversations', 'isLoading']),
  hasMore: state.getIn(['conversations', 'hasMore']),
  accounts: state.getIn(['accounts']),
  contexts: state.getIn(['contexts']),
  compose: state.getIn(['compose']),
});

/**
 * Mappe les actions Redux aux props du composant
 * @param {Function} dispatch - La fonction dispatch de Redux
 * @returns {Object} Les actions mappées pour le composant
 */
const mapDispatchToProps = dispatch => ({
  dispatch, onExpandConversations: () => dispatch(expandConversations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatAside);
